<script>
export default {
    name: "widthMd",
    methods: {
        widthMd(size = 1025, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        },
    }
}
</script>

<style scoped>

</style>