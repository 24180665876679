<template>
    <div></div>
</template>

<script>

import SimulatorConfig from '../services/SimulatorConfig';

export default {
    name: 'BuildSimulatorUrl',

    methods: {
        async buildSimulatorUrl(propertyId) {
            this.$store.commit('changeLoading', true);

            return await SimulatorConfig.getDataToBuildUrl(propertyId).then(async ({data}) => {
                const {useNewCalc, reserveId, companyId} = data;

                if (useNewCalc) {
                    const accessToken = reserveId ? `?accessToken=${localStorage.getItem('access_token')}` : '';

                    return `${process.env.VUE_APP_SIMULATOR_HOST}/${companyId}/${propertyId}${accessToken}`;
                } else {
                    const price = this.toCurrency(data.price).replaceAll('R$ ', '');

                    const uri = Buffer.from(data.financingCalculationFormula).toString('base64');

                    let params = `&loteamento=${data.allotmentName}&cidade=${data.cityName}&quadra=${data.block}&lote=${data.lot} ` +
                        `&lateral=${data.leftSize}&metragem=${data.totalArea}&valorLote=${price}&frente=${data.frontSize}` +
                        `&labelBalao=${data.labelBalloon}&entradaMin=${data.initialPaymentRequired}` +
                        `&isPercentage=${data.initialPaymentRequiredIsPercentage}&brokerageValue=${data.brokerageValue}`;

                    if (reserveId) {
                        params += `&isProposal=true&reserveId=${reserveId}&companyId=${companyId}&allotmentId=${data.allotmentId}&brokerageData=${this.base64_encode(data.brokerageData)}&accessToken=${localStorage.getItem('access_token')}`;
                    }

                    return (process.env.VUE_APP_HOST_APP_CALC_WITH_TOKEN + uri + params);
                }
            }).catch(err => {
                throw err;
            }).finally(() => this.$store.commit('changeLoading', false));
        },
    },
};

</script>
