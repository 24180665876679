import {http} from './config';

export default {
    create:(type) => {
        return http.post('api/v1/tipoReservas', type);
    },
    index:(page=1) => {
        return http.get('api/v1/tipoReservas?page=' + page);
    },
    listToLabel:() => {
        return http.get('api/v1/tipoReservas/label');
    },
    update:(type) => {
        return http.put('api/v1/tipoReservas/' + type.id, type);
    },
    delete: (id) => {
        return http.delete('api/v1/tipoReservas/' + id);
    },
    list: (allotment_id) => {
        return http.get('api/v1/tipoReservas/listar/' + allotment_id);
    },
    haveReserveNow:(data) => {
        return http.post('api/v1/tipoReservas/existe', data);
    },
}
