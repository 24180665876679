<template>
    <div class="modal-row2 modal-height-max" id="filter-reserva">
        <div class="modal-body">
            <div class="row gutters mt-2">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label>{{ t('NAV.ALLOTMENT') }}: </label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Loteamento'}"
                                 :options="allotments"
                                 v-model="allotmentid" @select="changeAllotment"/>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="form-group">
                        <label>{{ t('RESERVE.REALTOR') }}: </label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Corretor'}" :options="realtors"
                                 v-model="filter.realtorId" :disabled="allotmentid === null"/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="form-group">
                        <label>{{ t('RESERVE.STATUS') }}: </label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Status'}" :options="status"
                                 v-model="filter.reserveStatusType" :disabled="allotmentid === null"/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="form-group">
                        <label>{{ t('RESERVE.TYPE') }}: </label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Tipo'}"
                                 :options="[{id:'Nenhum', text: 'Nenhum'},...reserveTypes]"
                                 v-model="filter.reserveType" :disabled="allotmentid === null"/>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="form-group">
                        <label>{{ t('RESERVE.CLIENT') }}: </label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Cliente'}" :options="clients"
                                 v-model="filter.clientId" :disabled="allotmentid === null"/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div class="form-group">
                        <label>{{ t('PROPERTIES.BLOCK') }}: </label>
                        <Select2 :settings="{ width: '100%', placeholder: 'Selecione uma Quadra'}" :options="blocks"
                                 v-model="filter.block" @select="searchBlock($event)" :disabled="allotmentid === null"/>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div class="form-group">
                        <label>{{ t('PROPERTIES.NAME') }}: </label>
                        <Select2 id="lots" :settings="{ width: '100%', placeholder: 'Selecione um lote'}"
                                 :options="lots"
                                 v-model="filter.lots" @select="searchLots($event)"
                                 :disabled="allotmentid === null"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer right">
            <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal" @click="$emit('close')">
                {{ t('ACTIONS.CLOSE') }}
            </button>
            <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal" @click="$emit('closeClear')">
                Limpar
            </button>
            <button type="button" class="btn btn-primary mr-1" @click="search()">{{ t('ACTIONS.FILTER') }}</button>
        </div>
    </div>
</template>


<script>

import {useI18n} from "vue-i18n";
import Reserve from "@/services/Reserves";
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import {mapState} from "vuex";
import User from '@/services/user';
import Lots from '@/services/Lots';
import ReserveStatus from "@/services/ReserveStatus";
import ReserveTypes from '../../services/ReserveTypes';
import {useToast} from 'vue-toastification';

export default {
    name: 'filterReserve',
    mixins: [Validate],
    props: ['blocksIndex', 'statusIndex', 'clientsIndex', 'realtorsIndex', 'allotmentId', 'lotsIndex', 'perPageIndex'],
    components: {
        Select2,
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    data() {
        return {
            blocks: null,
            allotmentid: null,
            status: null,
            lots: [],
            blockId: null,
            itemsReserve: null,
            realtors: [{}],
            clients: [],
            reserveTypes: [],
            filterBlock: null,
            filter: {
                lots: null,
                block: null,
                clientId: null,
                realtorId: null,
                reserveStatusType: null,
            },
        }
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
        })
    },
    mounted() {
        if (this.allotments?.length == 1) {
            this.allotmentid = this.allotments[0].id;
            this.changeAllotment();
        } else if (this.allotments?.length > 1) {
            this.allotments.sort(function (a, b) {
                return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
            });
        }
        // this.blocks = [{id: 0, text: 'Nenhum'}, ...this.blocksIndex];
        // this.status = this.statusIndex;
        // this.clients = this.clientsIndex;
        // this.realtors = this.realtorsIndex;
        // this.lots = this.lotsIndex;
        // this.lots.unshift('Nenhum');
    },


    watch: {
        blocksIndex: function () {
            this.blocks = [{id: 0, text: 'Nenhum'}, ...this.blocksIndex];
        },
        lotsIndex: function () {
            this.lots = this.lotsIndex;
            this.lots.unshift('Nenhum');
        },
        statusIndex: function () {
            this.status = this.statusIndex;
        },

        clientsIndex: function () {
            this.clients = this.clientsIndex;
        },
        realtorsIndex: function () {
            this.realtors = this.realtorsIndex;
        }
    },

    methods: {
        searchBlock({text, id}) {
            this.blockId = id;
            this.filterblock = text == 'Nenhum' ? null : text;
        },
        searchLots({text}) {
            this.filter.lots = text == 'Nenhum' ? null : text;
        },
        async changeAllotment() {
            this.$store.commit('changeLoading', true);
            this.filter = {
                lots: null,
                block: null,
                clientId: null,
                realtorId: null,
                reserveStatusType: null,
            };
            this.clients = [];
            this.realtors = [];
            this.lots = [];
            this.blocks = null;
            this.startReserve(this.allotmentid);
            this.getRerservesType(this.allotmentid);
            await this.indexRealtor(this.allotmentid);
            await this.getLots(this.allotmentid);
            await this.indexBlocks(this.allotmentid);
            await this.indexStatus(this.allotmentid);
            localStorage.removeItem('reserveAllotmentId');
            localStorage.setItem('reserveAllotmentId', this.allotmentid);
            this.$store.commit('changeLoading', false);
        },
        getRerservesType(allotment_id) {
            ReserveTypes.list(allotment_id).then(resp => {
                this.reserveTypes = resp.data;
            })
        },
        async indexBlocks(allotment_id) {
            await Lots.blocksList(allotment_id).then(async resp => {
                if (!resp.data?.validations) {
                    resp.data = resp.data.map(i => {
                        return {
                            id: i.text,
                            text: i.text,
                        }
                    })
                    this.blocks = [{id: 0, text: 'Nenhum'}, ...resp.data];
                }
            }).catch(error => {
                this.errorMsg(error);
            });
        },

        async startReserve(allotment_id) {
            await this.indexReserve(allotment_id);
        },

        async indexReserve(allotment_id = this.allotmentId, page = 1) {
            this.$store.commit('changeLoading', true);
            await Reserve.index(this.filter, allotment_id, page).then(resp => {
                this.itemsReserve = resp.data;
                this.startAfterReseve(resp.data?.data);
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error);
            });
        },

        async startAfterReseve(reserves) {
            if (reserves) {
                await reserves.forEach((reserve,) => {
                    this.indexClients(reserve);
                });
                this.$store.commit('changeLoading', false);
            } else {
                this.$store.commit('changeLoading', false);
            }
        },

        indexClients(reseves) {
            reseves.reserves_clients.forEach(reserveClients => {
                if (reserveClients.clients) {
                    if (!this.clients.find(client => client.id == reserveClients.clients?.id)) {
                        this.clients.push({id: reserveClients.clients?.id, text: reserveClients.clients?.name});
                    }
                }
            });

            if (!this.clients.find(c => c.id == 0)) {
                this.clients.unshift({id: 0, text: this.t('GENERAL.NONE')});
            }
        },

        async indexStatus() {
            await ReserveStatus.list().then(resp => {
                if (!resp.data?.validations) {
                    this.status = resp.data;
                    this.status.unshift({id: 99, text: this.t('GENERAL.NONE')});
                }
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error);
                this.$store.commit('changeLoading', false);
            });
        },

        async getLots() {
            await Lots.getLotsByAllotment(this.allotmentid).then(resp => {
                this.lots = [{id: 0, text: 'Nenhum'}, ...resp.data];
            })
        },

        async indexRealtor(allotment_id) {
            await User.listRealtor(allotment_id).then(resp => {
                if (!resp.data.validations) {
                    this.realtors = [];
                    this.realtors = resp.data.filter(item => {
                        if (typeof item === 'object' && item !== null) {
                            return {
                                id: item.id,
                                text: item.text,
                            }
                        }
                    });
                    this.realtors.unshift({id: 0, text: this.t('GENERAL.NONE')});
                } else this.realtors = [{id: 0, text: 'Nenhum'}];
            }).catch(error => {
                this.errorMsg(error);
            });
        },

        verifyFilterNotEmpty()
        {
            if (!this.filter.block && !this.filter.clientId && !this.filter.lots && !this.filter.realtorId &&
            !this.filter.reserveStatusType && !this.allotmentid && !this.filter.reserveType){
                this.toast.error('Necessário Selecionar  um Filtro')
                return true;
            }
            return  false
        },

        search() {
            if (this.verifyFilterNotEmpty()){
                return;
            }

            this.$store.commit('changeLoading', true);
            this.checkFilterNone();

            Reserve.index(this.filter, this.allotmentid, 1, this.perPageIndex).then(resp => {
                this.$emit('search', resp, {...this.filter}, this.allotmentid);
                if (this.blockId != 0) {
                    this.filter.block = this.blockId;
                }

                document.getElementById('closeX').click();
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error);
            });
        },

        checkFilterNone() {
            this.filter.block = this.filter.block == 0 ? null : this.filterblock;
            this.filter.clientId = this.filter.clientId == 0 ? null : this.filter.clientId;
            this.filter.realtorId = this.filter.realtorId == 0 ? null : this.filter.realtorId;
            this.filter.reserveStatusType = this.filter.reserveStatusType == 99 ? null : this.filter.reserveStatusType;
            this.filter.reserveType = this.filter.reserveType === 'Nenhum' ? null : this.filter.reserveType;
        },
    }
}
</script>