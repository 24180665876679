<template>
    <div>
        <page-header v-if="hasCreatePermission" data-cy="reserve_index_title" btnNew="ACTIONS.NEW"
                     pageTitle="RESERVE.INDEX_TITLE"
                     :filter="true" @newType="setRegisterModal" @filter="setFilterModal"/>
        <page-header v-if="!hasCreatePermission" pageTitle="RESERVE.INDEX_TITLE"/>
        <div class="card" v-if="subtitles">
            <div class="tags-block mb-2 ml-2 row left-content justify-content-between paddin">
                <div class="d-flex flex-row align-items-center">
                    <div class="tags" v-for="sub in subtitles" :key="sub.id">
                        <div class="mr-2">
                            <i class="icon-label" :style="{'color': sub.text_color}"></i> {{ sub.name }}
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2" v-if="isAdmin === 1">
                    <div class="text-right mr-3">
                        <button data-cy="lot_index_dropdown" class="btn btn-custom bg-btn no-border" type="button"
                                id="import-buttom"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="icon-menu"/>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-lot w-auto"
                             id="dropdown-import">
                            <div class="dropdown-item bg-hover-black w-auto">
                                <a class="row item-custom2 ml-1 text-white pr-2"
                                   @click="$router.push('/corretores/reservas')">
                                    <i class="icon-eye1 mr-1 font-15"/>
                                    {{ !widthMd(1000) ? t('LOTS.VIEW_REALTORS_RESERVES') : 'Reservas por Corretor' }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="hastwoAllotments">
            <div class="card-header">
                <div class="card-title">
                    <label for="state">{{ t('NAV.ALLOTMENT') }}</label>
                </div>
            </div>
            <div class="card-body pt-0">
                <select2 :settings="{ width: '50%', placeholder:  'Todos'}" :options="allotments"
                         v-model="allotmentId" @select="changeAllotment($event)"/>
            </div>
        </div>

        <div class="container-custom" v-if="!widthPhone(575)" style="overflow-x: hidden">
            <div class="table">
                <div class="row gutters pd-10 reserve-header">
                    <div class="custom-th col-xl-1 col-lg-1 col-md-1 col-sm-2 col-5 text-left">
                        {{ t('RESERVE.STATUS') }}
                    </div>
                    <div class="custom-th col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 text-left">
                        {{ t('RESERVE.REALTOR') }}
                    </div>
                    <div class="custom-th col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 text-left">
                        {{ t('RESERVE.CLIENT') }}
                    </div>
                    <div class="custom-th col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6 text-left">
                        {{ t('RESERVE.ALLOTMENT') }}
                    </div>
                    <div class="custom-th col-xl-1 col-lg-1 col-md-2 col-sm-4 col-4 text-center">Obs.
                    </div>
                    <div class="custom-th col-xl-1 col-lg-1 col-md-1 col-sm-3 col-6 text-left">
                        {{ t('PROPERTIES.BLOCK') }}
                    </div>
                    <div class="custom-th col-xl-1 col-lg-1 col-md-1 col-sm-4 col-4 text-center">
                        <a class="pointer" @click="sort(items, 5)">{{ t('PROPERTIES.NAME') }}
                            <i class="icon-" :class="sortIcon(5)"/>
                        </a>
                    </div>
                    <div class="custom-th col-xl-1 col-lg-1 col-md-1 col-sm-4 col-4 text-center">
                        <a class="pointer" @click="sort(items, 6)">{{ t('RESERVE.DURATION') }}
                            <i class="icon-swap_vert" v-if="this.durationSort == 0"/>
                            <i class="icon-arrow_downward" v-if="this.durationSort == 2"/>
                            <i class="icon-arrow_upward" v-if="this.durationSort == 1"/>
                        </a>
                    </div>
                    <div class="custom-th col-xl-1 col-lg-1 col-md-2 col-sm-4 col-4 text-center">{{
                            t('ACTIONS.LABEL')
                        }}
                    </div>
                </div>
                <div v-if="(items && items.data)">
                    <div class="row gutters2" v-for="(iten, key) in items.data" :key="iten.id"
                         :style="{'min-height': items.data?.length < 2 ? '125px' :''}">
                        <div class="custom-td col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2" v-if="widthPhone()"><b>{{ t('RESERVE.STATUS') }}</b></label>
                                <div>{{ iten.reserve_status.name }}</div>
                            </div>
                        </div>
                        <div class="custom-td col-xl-2 col-lg-2 col-md-2 col-sm-5 col-5"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group" :class="widthPhone() ? 'text-center' : ''">
                                <label class="mb-2" v-if="widthPhone()"><b>{{ t('RESERVE.REALTOR') }}</b></label>
                                <div>{{ iten.realtorName }}</div>
                            </div>
                        </div>
                        <div class="custom-td col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}"
                             v-if="!widthPhone()">
                            {{ setClient(iten.reserves_clients) }}
                        </div>
                        <div class="custom-td col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}"
                             v-if="!widthPhone()">
                            {{ iten?.properties?.allotments?.name ? iten?.properties?.allotments?.name : 'Indefinido' }}
                        </div>
                        <div class="custom-td col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 bt text-center"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}">
                     <span id="dropInfo" class="mr-2">
                              <span class="dropdown" @mouseout="setModalHoover(`dropdownMenu${key}`)"
                                    @mouseover="setModalHoover(`dropdownMenu${key}`)">
                                 <i class="icon-info-with-circle ml-1"
                                    :id="`dropdownMenu${key}`"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"></i>
                                 <div class="dropdown-menu p-2" :aria-labelledby="`dropdownMenu${key}`"
                                      style="min-width: 30rem;">
                                    <div class="mb-2 p-2">
                                         <div class="form-group">
                                          <label for="exampleFormControlTextarea1">Observações:</label>
                                           <p id="exampleFormControlTextarea1"
                                              style="font-size: 13px"
                                              disabled
                                           >{{ iten.observation ? iten.observation : ' ' }}</p>
                                          </div>
                                    </div>
                                 </div>
                              </span>
                     </span>
                        </div>
                        <div class="custom-td col-xl-1 col-lg-1 col-md-1 col-sm-4 col-4 bt2"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}"
                             v-if="!widthPhone()">
                            {{ iten.properties.block }}
                        </div>
                        <div class="custom-td col-xl-1 col-lg-1 col-md-1 col-sm-4 col-4 bt"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group text-center">
                                <label class="mb-2" v-if="widthPhone()"><b>{{ t('PROPERTIES.NAME') }}</b></label>
                                <div>{{ iten.properties.name }}</div>
                            </div>
                        </div>
                        <div class="custom-td col-xl-1 col-lg-1 col-md-1 col-sm-3 col-3 bt"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2" v-if="widthPhone()"><b>{{ t('RESERVE.DURATION') }}</b></label>
                                <div class="text-center" :class="!widthPhone() ? 'text-center' : ''">
                                    {{ iten.reserveCooldown }}
                                </div>
                            </div>
                        </div>
                        <div class="custom-td col-xl-3 col-lg-3 col-md-3 col-sm-4 col-5 bt"
                             :class="key % 2 != 0 ? 'table-grey' : ''" :style="{color: iten.reserve_type.text_color}"
                             v-if="widthPhone()">
                            <div class="form-group" :class="widthPhone() ? 'text-center' : ''">
                                <label class="mb-2" v-if="widthPhone()"><b>{{ t('RESERVE.CLIENT') }}</b></label>
                                <div>{{ setClient(iten.reserves_clients) }}</div>
                            </div>
                        </div>

                        <div class="custom-td col-xl-1 col-lg-1 col-md-2 col-sm-4 col-4 bt br-0 text-center"
                             :class="key % 2 != 0 ? 'table-grey' : ''">
                            <div class="form-group">
                                <label class="mb-2" v-if="widthPhone()"
                                       :style="{color: iten.reserve_type.text_color}">
                                    <b>{{ t('ACTIONS.LABEL') }}</b>
                                </label>
                                <div>
                                    <a data-cy="reserve_index_confirm" class="mr-2 size green"
                                       :title="t('RESERVE.CONFIRM_RESERVATION')"
                                       v-if="iten.properties?.allotments?.user_allotment[0]?.is_realtor === false && iten.reserve_status.type == 0"
                                       data-target="#customModalTwo" data-toggle="modal">
                                        <i class="icon-thumbs-up pointer"
                                           @click="setConfirmModal(iten, iten.realtorName)"/>
                                    </a>
                                    <a data-cy="reserve_index_revoke" class="mr-1 size-red red"
                                       :title="t('RESERVE.DROP_RESERVATION')" data-target="#modalShow"
                                       data-toggle="modal"
                                       v-if="hasRevokePermission && (iten.reserve_status.type == 0)">
                                        <i class="icon-thumbs-down pointer"
                                           @click="setDropModal(iten, iten.realtorName)"/>
                                    </a>
                                    <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                       style="font-size: 20px">
                                        <i class="icon-more_horiz"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-reserve">
                                        <div data-cy="reserve_index_renew"
                                             @click="renew(iten.id, iten.properties.allotment_id)"
                                             v-if="iten.properties?.allotments?.user_allotment[0]?.is_realtor === false && iten.reserve_status.type == 0"
                                             class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.RENEW') }}
                                        </div>
                                        <div data-cy="reserve_index_renew"
                                             @click="setEditModal(iten, iten.realtorName)"
                                             data-target="#customModalTwo" data-toggle="modal"
                                             v-if="iten.reserve_status.type == 0"
                                             class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.EDIT') }}
                                        </div>
                                        <div data-cy="reserve_index_change_time"
                                             @click="undetermined(iten.id, iten.properties.allotment_id)"
                                             v-if="iten.properties?.allotments?.user_allotment[0]?.is_realtor === false && iten.reserve_status.type == 0"
                                             class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.CHANGE_TIME') }}
                                        </div>
                                        <div data-cy="reserve_index_historic" data-target="#modalShow"
                                             data-toggle="modal" @click="setHistoricModal(iten, iten.realtorName)"
                                             v-if="hasHistoricPermission" class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.HISTORIC') }}
                                        </div>
                                        <div data-cy="reserve_index_historic" data-target="#customModalTwo"
                                             data-toggle="modal"
                                             @click="setProposalModal(iten)"
                                             v-if="iten.hasProposal" class="dropdown-item pointer text-white">
                                            {{ t('PROPOSAL.PROPOSALS') }}
                                        </div>
                                        <div data-cy="reserve_index_historic" data-target="#customModalTwo"
                                             data-toggle="modal"
                                             v-if="iten.reserve_status.type == 0"
                                             @click="setSimulateModal(iten)"
                                             class="dropdown-item pointer text-white">
                                            {{ t('PROPOSAL.CREATE') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="items && items.data && !items.data[0] || items && items.validations">
                    <div class="row gutters2 pd-1-5">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                            {{ t('RESERVE.MSG.NONE_RECORD_FIND') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="widthPhone(575)">
            <div v-if="(items && items.data)">
                <div class="card" v-for="(iten) in items.data" :key="iten.id">
                    <div class="pd-top-left-right pdt-1 aling justify-content-between">
                        <div :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('RESERVE.STATUS') }}</b></label>
                                <div>{{ iten.reserve_status.name }}</div>
                            </div>
                        </div>
                        <div class="text-center" :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('PROPERTIES.BLOCK') }}</b></label>
                                <div>{{ iten.properties.block }}</div>
                            </div>
                        </div>
                        <div class="text-center" :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('PROPERTIES.NAME') }}</b></label>
                                <div>{{ iten.properties.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pd-top-left-right aling justify-content-between">
                        <div :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('RESERVE.REALTOR') }}</b></label>
                                <div>{{ iten.realtorName }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pd-top-left-right aling justify-content-between">
                        <div :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('RESERVE.CLIENT') }}</b></label>
                                <div>{{ setClient(iten.reserves_clients) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="pd-top-left-right aling justify-content-between">
                        <div :style="{color: iten.reserve_type.text_color}">
                            <div class="form-group">
                                <label class="mb-2"><b>{{ t('RESERVE.DURATION') }}</b></label>
                                <div>{{ iten.reserveCooldown }}</div>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="form-group">
                                <label class="mb-2"
                                       :style="{color: iten.reserve_type.text_color}"><b>{{
                                        t('ACTIONS.LABEL')
                                    }}</b></label>
                                <div>
                                    <a data-cy="reserve_index_confirm" class="mr-2 size green"
                                       :title="t('RESERVE.CONFIRM_RESERVATION')"
                                       v-if="iten.properties?.allotments?.user_allotment[0]?.is_realtor === false && iten.reserve_status.type == 0"
                                       data-target="#customModalTwo" data-toggle="modal">
                                        <i class="icon-thumbs-up pointer"
                                           @click="setConfirmModal(iten, iten.realtorName)" style="font-size: 23px"/>
                                    </a>
                                    <a data-cy="reserve_index_revoke" class="mr-1 size-red red"
                                       :title="t('RESERVE.DROP_RESERVATION')" data-target="#modalShow"
                                       data-toggle="modal"
                                       v-if="hasRevokePermission && (iten.reserve_status.type == 0)">
                                        <i class="icon-thumbs-down pointer"
                                           @click="setDropModal(iten, iten.realtorName)"/>
                                    </a>
                                    <a type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                       style="font-size: 24px">
                                        <i class="icon-more_horiz"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-reserve">
                                        <div data-cy="reserve_index_renew"
                                             @click="renew(iten.id, iten.properties.allotment_id)"
                                             v-if="iten.properties?.allotments?.user_allotment[0]?.is_realtor === false && iten.reserve_status.type == 0"
                                             class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.RENEW') }}
                                        </div>
                                        <div data-cy="reserve_index_renew"
                                             @click="setEditModal(iten, iten.realtorName)"
                                             data-target="#customModalTwo" data-toggle="modal"
                                             v-if="iten.reserve_status.type == 0"
                                             class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.EDIT') }}
                                        </div>
                                        <div data-cy="reserve_index_change_time"
                                             @click="undetermined(iten.id, iten.properties.allotment_id)"
                                             v-if="iten.properties?.allotments?.user_allotment[0]?.is_realtor === false && iten.reserve_status.type == 0"
                                             class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.CHANGE_TIME') }}
                                        </div>
                                        <div data-cy="reserve_index_historic" data-target="#modalShow"
                                             data-toggle="modal" @click="setHistoricModal(iten, iten.realtorName)"
                                             v-if="hasHistoricPermission" class="dropdown-item pointer text-white">
                                            {{ t('ACTIONS.HISTORIC') }}
                                        </div>
                                        <div data-cy="reserve_index_historic" data-target="#customModalTwo"
                                             data-toggle="modal"
                                             @click="setProposalModal(iten)"
                                             v-if="iten.hasProposal" class="dropdown-item pointer text-white">
                                            {{ t('PROPOSAL.PROPOSALS') }}
                                        </div>
                                        <div data-cy="reserve_index_historic" data-target="#customModalTwo"
                                             data-toggle="modal" v-if="iten.reserve_status.type == 0"
                                             @click="setSimulateModal(iten)"
                                             class="dropdown-item pointer text-white">
                                            {{ t('PROPOSAL.CREATE') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row gutters">
            <div class="col-3" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-6' : 'col-12'">
                <pagination-component v-if="items && items.data && items.data[0]" :items="items" :to="'/reserva'"
                                      @changePage="indexReserve(allotmentId, $event, true)"></pagination-component>
            </div>
            <div class="col-3" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="items && items.data && items.data[0]">
                    <div class="col-8">
                        <label>Reservas por página: </label>
                        <select2 :settings="{width: '100%'}" placeholder="Selecione" @select="changePaginate"
                                 :options="paginate" id="paginate" name="paginate" v-model="perPage"/>
                    </div>
                </div>
            </div>
        </div>


        <modal :modal-extra-large="wichModal === 7" :modal-xl="wichModal !== 7" :titleModal="modalTitle"
               @close="reloadIndex(false)" v-if="wichModal !== 5">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <create-reserve v-if="reserve && wichModal === 1" :reserveIndex="reserve" :allotmentsIndex="allotments"
                            :blocksIndex="blocksIndex" :reserveTypesIndex="reserveTypesIndex"
                            :is-data-required="isDataRequired"
                            :is-email-required="isEmailRequired"
                            :isRealtor="!hasConfirmPermission" @created="updateAfterCreated" @close="reloadIndex">
            </create-reserve>
            <confirm-reserve v-if="reserveIndex && wichModal === 2"
                             :reserveIndex="reserveIndex"
                             :modalTitle="modalTitle"
                             :is-data-required="isDataRequired"
                             :is-email-required="isEmailRequired"
                             @close="this.indexAllotment()"
                             :isConfirm="isConfirm" @confirm="updateAfter">
            </confirm-reserve>
            <edit-reserve-component v-if="reserveIndex && wichModal === 8"
                                    :reserveIndex="reserveIndex"
                                    :modalTitle="modalTitle"
                                    :is-data-required="isDataRequired"
                                    :is-email-required="isEmailRequired"
                                    @close="this.indexAllotment()"
                                    :isConfirm="isConfirm" @confirm="updateAfter"
            ></edit-reserve-component>
            <filter-reserve v-if="wichModal === 5" :blocksIndex="blocks" :statusIndex="status" :clientsIndex="clients"
                            :realtorsIndex="realtors" :allotmentId="allotmentId" :lotsIndex="lots"
                            @search="updateAfterSearch" @close="reloadIndex(false)" @closeClear="closeFilter()">
            </filter-reserve>
            <proposal-list-by-reserve-component
                :reserveIndex="reserveIndex"
                :modalTitle="modalTitle"
                v-if="wichModal === 6"/>
            <iframe style="height: 80vh!important;" :src="srcIframe"
                    v-if="wichModal === 7 && srcIframe"/>

            <div class="modal-footer right-content" v-if="wichModal === 7">
                <button data-cy="lot_index_simulate_close" @click="closeModalProposal" class="btn btn-secondary"
                        data-dismiss="modal" type="button">
                    {{ t('ACTIONS.CLOSE') }}
                </button>
            </div>
        </modal>

        <modal :titleModal="modalTitle" :modal-xl="true" v-if="wichModal === 5" @close="reloadIndex(false)">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <filter-reserve v-if="wichModal === 5" :blocksIndex="blocks" :statusIndex="status" :clientsIndex="clients"
                            :realtorsIndex="realtors" :allotmentId="allotmentId"
                            :lotsIndex="lots"
                            :perPageIndex="perPage"
                            @search="updateAfterSearch"
                            @close="reloadIndex(false)"
                            @closeClear="closeFilter()"
            ></filter-reserve>
        </modal>
        <modals-reserve v-if="reserveIndex && (wichModal === 3 || wichModal === 4)" :reserveIndex="reserveIndex"
                        :modalTitle="modalTitle" @drop="updateAfter" :wichModal="wichModal">
        </modals-reserve>
        <modal-select-allotment v-if="showModalAllotment" :showModal="showModalAllotment"
                                :is-reserve="true"
                                @allotmentSelected="allotmentModalSelect">
        </modal-select-allotment>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import User from '@/services/user';
import Lots from '@/services/Lots';
import jwt_decode from 'jwt-decode';
import Modal from '@/components/modal';
import Reserve from '@/services/Reserves';
// import Public from '../../services/Public';
import widthMd from '../../mixins/widthMd';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import ReserveTypes from '@/services/ReserveTypes';
import Allotments from '../../services/Allotments';
import Permission from '@/components/permission.vue';
import ReserveStatus from '@/services/ReserveStatus';
import createReserve from './createReserveComponent';
import pageHeader from '@/components/layouts/pageHeader';
import modalsReserve from './modalsReserveComponent.vue';
import filterReserve from './filterReserveComponent.vue';
import editReserveComponent from './editReserveComponent';
import confirmReserve from './confirmReserveComponent.vue';
import BuildSimulatorUrl from '@/Helpers/BuildSimulatorUrl';
import PaginationComponent from '@/components/layouts/PaginationComponent';
import proposalListByReserveComponent from './proposalListByReserveComponent';
import modalSelectAllotment from '../allotment/modalSelectAllotmentComponent.vue';

export default {
    name: 'IndexReserve',

    mixins: [Validate, Permission, widthMd, BuildSimulatorUrl],

    components: {
        Modal,
        Select2,
        pageHeader,
        filterReserve,
        createReserve,
        modalsReserve,
        confirmReserve,
        PaginationComponent,
        editReserveComponent,
        modalSelectAllotment,
        proposalListByReserveComponent,
    },

    beforeRouteLeave() {
        localStorage.removeItem('reserveAllotmentId');
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    computed: {
        ...mapState({
            paginate: 'paginate',
            allotmentsState: 'allotments',
            allotmentLoad: 'allotmentLoad',
            reserveCooldown: 'reserveCooldown',
            hastwoAllotments: 'hastwoAllotments',
        }),
    },

    data() {
        return {
            lots: {},
            blocks: [],
            lotSort: 0,
            items: null,
            clients: [],
            status: null,
            reserve: null,
            perPage: null,
            isAdmin: false,
            realtors: null,
            allotments: [],
            modalTitle: '',
            srcIframe: null,
            loopReserve: [],
            wichModal: null,
            durationSort: 0,
            subtitles: null,
            isConfirm: false,
            howSorting: null,
            realtorIndex: [],
            isHistoric: false,
            blocksIndex: null,
            allotmentId: null,
            reserveIndex: null,
            isDataRequired: null,
            isEmailRequired: null,
            reserveTypesIndex: null,
            hasListPermission: false,
            hasShowPermission: false,
            hasRenewPermission: true,
            showModalAllotment: false,
            hasCreatePermission: false,
            hasUpdatePermission: false,
            hasDeletePermission: false,
            hasRevokePermission: false,
            showAllotmentSelect: false,
            hasConfirmPermission: true,
            hasHistoricPermission: false,
            hasUndeterminedPermission: true,
            filter: {
                block: null,
                clientId: null,
                realtorId: null,
                reserveStatusType: null,
                lots: null,
            },
        };
    },

    async mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        localStorage.setItem('reserveAllotmentId', 'all');
        this.$store.commit('changeLoading', true);
        await this.indexAllotment();
        this.indexReserveType();
        if (this.allotmentsState) {
            this.allotments = JSON.parse(JSON.stringify(this.allotmentsState));
            this.allotments.sort(function (a, b) {
                return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
            });
            this.allotments.unshift({id: 'all', text: 'Todos'});
        }
        this.allotmentId = 'all';
        await this.startAfterReseve();
    },

    watch: {
        allotments: async function () {
            this.$store.commit('changeLoading', true);
            this.allotmentId = localStorage.getItem('reserveAllotmentId');
            await this.indexAllotment();
        },
        allotmentsState: async function () {
            this.allotments = JSON.parse(JSON.stringify(this.allotmentsState));
            this.allotments.sort(function (a, b) {
                return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
            });
            this.allotments.unshift({id: 'all', text: 'Todos'});
            this.allotmentId = localStorage.getItem('reserveAllotmentId');
        },
    },

    methods: {
        handleBeforeUnload() {
            if (localStorage.getItem('reserveAllotmentId') === 'all') {
                localStorage.removeItem('reserveAllotmentId');
            }
            window.removeEventListener('beforeunload', this.handleBeforeUnload);
        },

        changePaginate() {
            this.indexReserve();
        },

        setModalHoover(id) {
            document.getElementById(id).click();
        },

        async setSimulateModal(reserve) {
            if (!reserve?.reserves_clients?.length) {
                setTimeout(() => document.getElementById('closeX')?.click(), 500);
                this.$store.commit('changeLoading', true);
                this.toast.error('Não é possivel gerar proposta para reserva que não possui clientes vinculados');
                return;
            }
            this.wichModal = 7;
            this.modalTitle = 'LOTS.CREATE_PROPOSE';
            const {properties} = reserve;

            await this.buildSimulatorUrl(properties.id).then(url => this.srcIframe = url).catch(err => this.errorMsg(err));
        },

        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },

        base64_encode(string) {
            return btoa(unescape(encodeURIComponent(string)));
        },

        setProposalModal(iten) {
            this.wichModal = 6;
            this.$store.commit('changeLoading', true);

            Reserve.showProposals(iten.id).then(resp => {
                this.reserveIndex = resp.data;
                this.$store.commit('changeLoading', false);
                this.modalTitle = 'PROPOSAL.PROPOSALS';
                this.wichModal = 6;
            }).catch(() => this.$store.commit('changeLoading', false));
        },

        async getLots() {
            await Lots.getLotsByAllotment(this.allotmentId).then(resp => this.lots = resp.data);
        },

        closeFilter() {
            this.filter = {
                block: null,
                clientId: null,
                realtorId: null,
                reserveStatusType: null,
                lots: null,
            };
            this.wichModal = null;
            this.filter.reserveStatusType = '0';
            this.indexReserve();
        },

        async indexReserve(allotment_id = this.allotmentId, page = 1, paginate = null) {
            this.$store.commit('changeLoading', true);
            if (paginate) {
                this.loopReserve.forEach(item => clearInterval(item));
            }
            await Reserve.index(this.filter, allotment_id, page, this.perPage).then(resp => {
                this.items = resp.data;
                this.startAfterReseve(resp.data?.data);
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error);
            });
        },

        indexBlocks(allotment_id) {
            Lots.blocksListAvailable(allotment_id).then(async resp => {
                if (!resp.data?.validations) {
                    await (this.blocks = resp.data);
                }
            }).catch(error => this.errorMsg(error));
        },

        async indexRealtor(allotment_id) {
            await User.listRealtor(allotment_id).then(resp => {
                if (!resp.data.validations) {
                    this.realtors = resp.data;
                    this.realtors.unshift({id: 0, text: this.t('GENERAL.NONE')});
                } else this.realtors = null;

            }).catch(error => this.errorMsg(error));
        },

        indexClients(reseves) {
            reseves.reserves_clients.forEach(reserveClients => {
                if (reserveClients.clients) {
                    if (!this.clients.find(client => client.id == reserveClients.clients?.id)) {
                        this.clients.push({id: reserveClients.clients?.id, text: reserveClients.clients?.name});
                    }
                }
            });

            if (!this.clients.find(c => c.id == 0)) {
                this.clients.unshift({id: 0, text: this.t('GENERAL.NONE')});
            }
        },

        indexStatus() {
            ReserveStatus.list().then(resp => {
                if (!resp.data?.validations) {
                    this.status = resp.data;
                    this.status.unshift({id: 99, text: this.t('GENERAL.NONE')});
                }
            }).catch(error => this.errorMsg(error))
                .finally(() => this.$store.commit('changeLoading', false));
        },

        async indexAllotment() {
            if (this.wichModal == 1) return;
            this.showAllotmentSelect = this.hastwoAllotments == true && (localStorage.getItem('reserveAllotmentId') ? false : true);
            this.isLoading = this.showAllotmentSelect ? false : true;

            if (this.allotmentLoad) {
                if (this.hastwoAllotments == false) {
                    this.allotmentId = this.allotmentsState[0]?.id;
                } else {
                    this.allotmentId = localStorage.getItem('reserveAllotmentId');
                }
                this.startPermission();
                await this.startReserve(this.allotmentId);
            }
        },

        indexReserveType() {
            ReserveTypes.listToLabel()
                .then(resp => this.subtitles = resp.data)
                .catch(error => this.errorMsg(error));
        },

        allotmentSelected() {
            this.$store.commit('changeLoading', true);
            this.allotmentId = localStorage.getItem('reserveAllotmentId');
            this.startReserve(this.allotmentId);
        },

        changeAllotment({id}) {
            this.$store.commit('changeLoading', true);
            this.isDataRequired = null;
            this.isEmailRequired = null;
            this.loopReserve.forEach(loop => clearInterval(loop));

            this.startReserve(id);
            localStorage.removeItem('reserveAllotmentId');
            localStorage.setItem('reserveAllotmentId', id);
        },

        async startReserve(allotment_id, reloadF = true) {
            if (reloadF) {
                this.filter.reserveStatusType = '0';
            }
            await this.indexReserve(allotment_id);
        },

        permissionsForProfile(allotment_id) {
            User.permissions(allotment_id).then(resp => {
                for (const id in resp?.data) {
                    switch (resp.data[id]) {
                        case 'Reserve:renew':
                            this.hasRenewPermission = false;
                            break;
                        case 'Reserve:confirm':
                            this.hasConfirmPermission = false;
                            break;
                        case 'Reserve:undetermined':
                            this.hasUndeterminedPermission = false;
                            break;
                    }
                }
            });
        },

        startPermission() {
            this.hasShowPermission = this.validatePermissions('Reserve:show');
            this.hasListPermission = this.validatePermissions('Reserve:list');
            this.hasCreatePermission = this.validatePermissions('Reserve:create');
            this.hasDeletePermission = this.validatePermissions('Reserve:delete');
            this.hasRevokePermission = this.validatePermissions('Reserve:revoke');
            this.hasHistoricPermission = this.validatePermissions('Reserve:historic');

            const companies = jwt_decode(localStorage.getItem('access_token'))
                .roles['companies'];
            companies.forEach(company => {
                company.companies.forEach(id => {
                    if (localStorage.getItem('companyId') === id) {
                        this.isAdmin = company.group_id;
                    }
                });
            });
        },

        closeModalProposal() {
            document.getElementById('closeX').click();
            this.reloadIndex();
        },

        async reloadIndex(reloadF = true) {
            this.wichModal = null;
            this.isDataRequired = null;
            this.isEmailRequired = null;
            this.loopReserve.forEach(loop => clearInterval(loop));
            await this.startReserve(this.allotmentId, reloadF);
        },

        async allotmentModalSelect() {
            document.getElementById('closeX').click();
            this.showModalAllotment = false;
            this.allotmentId = localStorage.getItem('reserveAllotmentId');
            setTimeout(() => document.getElementById('new').click(), 1000);
        },

        setRegisterModal() {
            this.wichModal = 1;
            this.$store.commit('changeLoading', true);
            this.isEmailRequired = null;
            this.isDataRequired = null;
            Reserve.verifyLimitReserve(this.allotmentId).then(() => {
                this.wichModal = 1;
                this.reserve = {
                    blocks: [],
                    clients: [],
                    realtors: [],
                    observation: '',
                    isLotOrigin: false,
                    reserve_type_id: null,
                    allotments: this.allotments,
                    allotment_id: this.allotmentId,
                };
                if (!this.hasConfirmPermission && !this.reserveTypesIndex[0]) {
                    this.toast.error(this.t('GENERAL.MSG.DONT_HAVE_RESERVE_TYPE_REGISTER'), {timeout: false});
                }
                this.modalTitle = 'RESERVE.MODAL_TITLE.ADD';
            }).catch(err => {
                if (err.response) {
                    if (err.response?.data?.validations?.message === 'Necessário selecionar um loteamento válido') {
                        this.wichModal = null;
                        this.showModalAllotment = true;
                    } else {
                        this.errorMsg(err);
                        setTimeout(() => {
                            this.$store.commit('changeLoading', false);
                            document.getElementById('closeX').click();
                        }, 1000);
                    }
                }
            });
            Allotments.show(this.allotmentId).then(resp => {
                this.isDataRequired = resp.data.is_required_data_client;
                this.isEmailRequired = resp.data.is_required_email;
            });
        },

        updateAfterCreated() {
            this.indexReserve(this.allotmentId);
        },

        setClient(clients) {
            let client;
            clients.forEach((element, key) => {
                if (element.clients) {
                    client = key === 0 ? element.clients?.name : client + ', ' + element.clients?.name;
                }
            });

            if (typeof client == 'undefined') {
                client = this.t('GENERAL.MSG.NONE_COSTUMER_LIKED');
            }

            return client;
        },

        indexDuration(iten, key) {
            if (iten.permanences_active[0] && iten.permanences_active[0].finish) {
                let finish = iten.permanences_active[0].finish;

                const time = new Date();
                const timeFinish = new Date(finish);

                if (timeFinish < time) {
                    this.items.data[key].reserveCooldown = '00:00';

                } else {
                    let cooldown = (timeFinish.getTime() - time.getTime()) / 1000;

                    let hours = parseInt(cooldown / 3600);
                    cooldown = cooldown % 3600;

                    let minutes = parseInt(cooldown / 60);
                    let seconds = parseInt(cooldown % 60);

                    let hoursToShow = hours < 10 ? '0' + hours : hours;
                    let minutesToShow = minutes < 10 ? '0' + minutes : minutes;
                    let secondsToShow = seconds < 10 ? '0' + seconds : seconds;

                    this.items.data[key].reserveCooldown = hoursToShow + ':' + minutesToShow + ':' + secondsToShow;

                    this.loopReserve.push(setInterval(() => {
                        if (seconds == 0 && minutes == 0 && hours == 0) {
                            clearInterval(this.loopReserve[key]);
                            this.items.data[key].reserveCooldown = '00:00';
                        } else {
                            let timeNow = new Date();
                            let cooldown = (timeFinish.getTime() - timeNow.getTime()) / 1000;

                            hours = parseInt(cooldown / 3600);
                            cooldown = cooldown % 3600;

                            minutes = parseInt(cooldown / 60);
                            seconds = parseInt(cooldown % 60);

                            hoursToShow = hours < 10 ? '0' + hours : hours;
                            minutesToShow = minutes < 10 ? '0' + minutes : minutes;
                            secondsToShow = seconds < 10 ? '0' + seconds : seconds;
                            if (!this.items?.data[key]?.reserveCooldown) {
                                if (this.items.data[key]) {
                                    this.items.data[key] = [];
                                    this.items.data[key].push({reserveCooldown: null});
                                }

                            }
                            this.items.data[key].reserveCooldown = hoursToShow + ':' + minutesToShow + ':' + secondsToShow;
                        }
                    }, 1000));
                }
            } else if (!iten.permanences_active[0] || iten.permanences_active[0] && !iten.permanences_active[0].finish) {
                this.items.data[key].reserveCooldown = '?';
            }
        },

        async setConfirmModal(reserve, realtor_name) {
            this.$store.commit('changeLoading', true);
            this.isDataRequired = null;
            this.isEmailRequired = null;
            this.isConfirm = true;
            this.isHistoric = false;
            this.modalTitle = 'RESERVE.CONFIRM_RESERVE';
            if (reserve.properties?.allotment_id) {
                Allotments.show(reserve.properties.allotment_id).then(resp => {
                    this.isDataRequired = resp.data.is_required_data_client;
                    this.isEmailRequired = resp.data.is_required_email;
                });
            }
            await Reserve.show(reserve.id).then(resp => {
                this.reserveIndex = resp.data;
                this.reserveIndex.realtor_name = realtor_name;
                this.wichModal = 2;
            }).finally(() => this.$store.commit('changeLoading', false));
        },

        async setEditModal(reserve, realtor_name) {
            this.$store.commit('changeLoading', true);
            this.isDataRequired = null;
            this.isEmailRequired = null;
            this.isConfirm = true;
            this.isHistoric = false;
            this.modalTitle = 'RESERVE.EDIT_RESERVE';
            this.wichModal = 8;
            Allotments.show(reserve.properties.allotment_id).then(resp => {
                this.isDataRequired = resp.data.is_required_data_client;
                this.isEmailRequired = resp.data.is_required_email;
            });
            await Reserve.show(reserve.id).then(resp => {
                this.reserveIndex = resp.data;
                this.reserveIndex.realtor_name = realtor_name;
                this.$store.commit('changeLoading', false);
            }).finally(() => this.$store.commit('changeLoading', false));
        },

        setDropModal(reserve, realtor) {
            this.wichModal = 3;
            this.isConfirm = false;
            this.isHistoric = false;
            this.reserveIndex = reserve;
            this.reserveIndex.realtor = realtor;
            this.modalTitle = 'RESERVE.MSG.REALLY_WHANT_DROP_THIS_RESERVE';
        },

        setHistoricModal(reserve, realtor) {
            this.wichModal = 4;
            this.isHistoric = true;
            this.isConfirm = false;
            this.reserveIndex = reserve;
            this.reserveIndex.realtor = realtor;
            this.modalTitle = 'ACTIONS.HISTORIC';
        },

        async setFilterModal() {
            this.$store.commit('changeLoading', true);
            this.wichModal = 5;
            this.modalTitle = 'ACTIONS.FILTER';
            this.$store.commit('changeLoading', false);
        },

        async updateAfterSearch(reserve, filter, id) {
            this.allotmentId = id;
            this.filter = filter;
            this.items = reserve.data;
            this.loopReserve.forEach(loop => clearInterval(loop));

            this.lotSort = 0;
            this.durationSort = 0;

            if (reserve.data?.data) {
                await this.indexRealtor(this.allotmentId);
                this.startAfterReseve(reserve.data?.data);
            }
        },

        async startAfterReseve(reserves) {
            if (reserves) {
                await reserves.forEach((reserve, key) => {
                    this.indexClients(reserve);
                    this.indexDuration(reserve, key);
                    if (this.realtors) {
                        this.realtorIndex[key] = this.realtors.find(r => r.id == reserve.realtor_id)?.text;
                    }
                });
            }

            this.$store.commit('changeLoading', false);
        },

        renew(reserve_id, allotment_id = null) {
            this.$store.commit('changeLoading', true);

            Reserve.renew(reserve_id, allotment_id).then(() => {
                this.loopReserve.forEach(loop => clearInterval(loop));
                this.indexReserve(this.allotmentId);
            }).catch(error => this.errorMsg(error))
                .finally(() => this.$store.commit('changeLoading', false));
        },

        undetermined(reserve_id, allotment_id) {
            this.$store.commit('changeLoading', true);

            Reserve.undetermined(reserve_id, allotment_id).then(() => {
                this.loopReserve.forEach(loop => clearInterval(loop));
                this.indexReserve(this.allotmentId);
            }).catch(error => this.errorMsg(error))
                .finally(() => this.$store.commit('changeLoading', false));
        },

        updateAfter() {
            this.$store.commit('changeLoading', true);

            this.loopReserve.forEach(loop => clearInterval(loop));
            this.indexReserve(this.allotmentId);
            this.$store.commit('changeLoading', false);
        },

        sort(items, howSorting) {
            if (items && !items.validations) {
                this.$store.commit('changeLoading', true);
                let filter = {sort: null, name: null};

                if (this.howSorting && this.howSorting.id == howSorting) {
                    this.howSorting.type == 1 ? this.howSorting.type-- : this.howSorting.type++;
                    filter.sort = this.howSorting.type;
                } else {
                    this.howSorting = {
                        type: 0,
                        id: howSorting,
                    };
                }

                this.loopReserve.forEach(loop => clearInterval(loop));

                Reserve.index(filter, this.allotmentId, 1, this.perPage).then(resp => {
                    this.items = resp.data;
                    this.startAfterReseve(resp.data?.data);
                });

                this.$store.commit('changeLoading', false);
            }
        },

        sortIcon(howId) {
            if (this.howSorting && this.howSorting.id == howId) {
                return this.howSorting.type == 1 ? 'arrow_upward' : 'arrow_downward';
            } else {
                return 'swap_vert';
            }
        },
    },
};
</script>
<style>
.size {
    font-size: 160%;
}

.size-red {
    font-size: 160%;
    width: 20px !important;
}

.red {
    color: #BF0505;
}

.green {
    color: #0e5e4e;
}

.zIndex {
    z-index: 100000000000 !important;
}

.paddin {
    padding-left: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
}

.task-list .task-block .dropdown-menu:before {
    border-bottom: 0px solid #262b31 !important;
}

.reserve-header {
    display: flex;
    flex-wrap: wrap;
    align-items: self-end;
}

.aling {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pd-top-left-right {
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.pdt-1 {
    padding-top: 1rem;
}

.dropdown-reserve {
    width: 175px;
    left: 10% !important;
}

@media screen and (max-width: 575px) {
    .pd-top-left-right {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .page-header {
        padding: 0rem;
    }

    .dropdown-reserve {
        left: 3% !important;
    }
}
</style>
