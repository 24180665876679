<template>
    <div v-if="showModal">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header modal-header-color">
                                <h5 class="modal-title" id="modalId">{{ t('APP.MODAL.ALLOTMENT') }}</h5>
                            </div>
                            <div class="modal-body modal-open pb-0">
                                <div class="row gutters">
                                    <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                                        <div class="form-group mb-1">
                                            <input type="text" name="allotment" v-model="allotmentModal" id="allotment"
                                                   class="form-control mb-2" @keyup="selectModal"
                                                   placeholder="Digite o nome do loteamento para buscar">
                                            <div class="justify-content-center modal-row2">
                                                <div v-for="allotment in allotmentsModal" :key="allotment.id">
                                                    <button class="btn btn-background mt-1"
                                                            @click="allotmentSelected(allotment.id)">
                                                        {{ allotment.text }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" @click="$router.go(-1)">{{ t('ACTIONS.BACK') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from "vue-i18n";

export default {
    nome: 'modalSelectAllotment',
    //  props: ['showModal',],
    props: {
        isReserve: {
            default: false,
            type: Boolean
        },
        isProposal: {
            default: false,
            type: Boolean
        },
        showModal: {
            default: false,
            type: Boolean
        }
    },
    setup() {
        const {t} = useI18n();
        return {t}
    },

    computed: {
        ...mapState({
            allotments: 'allotments',
        }),
    },

    data() {
        return {
            aux: [],
            backup: [],
            allotmentId: null,
            allotmentsModal: [],
            allotmentModal: null,
        }
    },

    mounted() {
        this.allotmentsModal = this.allotments;
    },

    created() {
        this.backup = this.allotments;
    },

    methods: {
        selectModal() {
            this.aux = [];
            this.allotmentsModal = this.backup;
            this.allotmentsModal.forEach(allotment => {

                const text = allotment.text.toUpperCase();
                const textModal = this.allotmentModal.toUpperCase();

                if (text.includes(textModal)) {
                    this.aux.push(allotment);
                }
            })
            this.allotmentsModal = this.aux;
        },

        allotmentSelected(allotment_id) {
            this.$emit('showModal', false);
            this.allotmentId = allotment_id;
            if (this.isReserve) {
                localStorage.removeItem('reserveAllotmentId');
                localStorage.setItem('reserveAllotmentId', allotment_id);
            } else if (this.isProposal) {
                localStorage.removeItem('proposalAllotmentId');
                localStorage.setItem('proposalAllotmentId', allotment_id);
            } else {
                localStorage.removeItem('allotmentId');
                localStorage.setItem('allotmentId', allotment_id);
            }

            this.$emit('allotmentSelected');
        },
    }
}
</script>
