import {http} from './config';

export default {
    create:(client) => {
        return http.post('api/v1/clientes', client);
    },
    dashboard:() => {
        return http.get('api/v1/dashboard/clientes');
    },
    exportPdfClient: (id)=> {
        return http.get('api/v1/exportar/pessoas/pdf',{
            params:{"0": id},
        });
    },
    show:(id) => {
        return http.get('api/v1/clientes/' + id);
    },
    index:(page = 1, filter, perpage = null) => {
        if (perpage === 'selecione') perpage = null;
        return http.get('api/v1/clientes?page=' + page, {params: {...filter, perpage}});
    },
    delete: (id) => {
        return http.delete('api/v1/clientes/' + id);
    },
    showByTaxpayer:(taxpayer) => {
        return http.get('api/v1/clientes/ver/' + taxpayer);
    },
    update:(client) => {
        return http.put('api/v1/clientes/' + client.id, client);
    },
    showWhenCreate:(taxpayer) => {
        return http.get(`api/v1/clientes/show/${taxpayer}`);
    },
    listProfessions: () => {
        return http.get(`api/v1/profissoes/listar`);
    },
    listClientsToSales:(term) => {
        if (!term){
            return http.get('api/v1/clientes/list');
        }
        return http.get('api/v1/clientes/list?term=' + term);
    },
    importFile:(file) => {
        const formData = new FormData();
        formData.append('file', file);
        return http.post('api/v1/importar/pessoas', formData);
    },
    exportFile: () => {
        return http.get('/api/v1/exportar/pessoas',  {responseType: 'blob'});
    },
    numberValid: (number) => {
        return http.post("/api/v1/clientes/numberIsValid", {number : `55${number}`});
    }
}
