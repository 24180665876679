<script>

import jwt_decode from "jwt-decode";

export default {
   name: "Permission",

   data() {
      return {
         rules: null,
         idCompany: localStorage.getItem('companyId'),
         permissions: null,
      }
   },

   mounted() {
      this.rules = jwt_decode(localStorage.getItem('access_token')).roles;
      this.startPermissions()
   },

   methods: {

      startPermissions() {
         this.rules.companies.forEach(companies => {
            companies.companies.forEach(company => {
               if (company === this.idCompany) {
                  for (let index in this.rules) {
                     if (index !== 'companies' && this.rules[index]?.group_id === companies.group_id) {
                        this.permissions = this.rules[index].roles;
                     }
                  }
               }
            })
         });
      },

      validatePermissions(permission) {
         var hasPermisson = false;

         if (this.permissions && permission != '') {
            this.permissions.forEach(per => {
               if (per == permission)
                  hasPermisson = true;
            });
         }

         return hasPermisson;
      },
   }
}

</script>
